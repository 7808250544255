// libs
import { memo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// helpers
import { log } from 'shared/helpers/newRelic';
import { storeUserFormKey } from 'shared/helpers/api/authFormKeyStorage';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

// defs
interface UserInitProps {
  fetchUser: () => any;
}

const UserInit = ({ fetchUser }: UserInitProps) => {
  useEffect(() => {
    fetchUser().then((data) => {
      try {
        const { form_Key: formKey, customerData } = data;
        const {
          mobile: phone,
          email,
          firstname: firstName,
          lastname: lastName,
          id: customerId,
        } = customerData || {};

        // verify if user is logged in
        if (isEmpty(customerData) === false) {
          NFA.setUserData({ phone, email, firstName, lastName, customerId });
          NFA.setIsLogin(true);
        } else {
          NFA.setIsLogin(false);
        }

        // dont set form key if empty, would overwrite cached value
        if (!formKey || formKey.length === 0) {
          return;
        }

        storeUserFormKey(formKey);
      } catch (err) {
        log({
          errTitle: 'Error in UserInit - Boot',
          error: err,
          tags: { [TAG_IDENTIFIER.MODULE]: 'BOOT-AUTH' },
        });
        NFA.setIsLogin(false);
      }
    });
  }, [fetchUser]);

  return null;
};

export default memo(UserInit);
