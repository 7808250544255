// libs
import get from 'lodash/get';
import { Dispatch } from 'redux';

// api
import {
  addToWishlist as addProduct,
  getWishlistItemIds,
  removeFromWishlist as removeProduct,
} from 'shared/api/wishlist';

// actions
import {
  ActionTypes,
  addProductIdToWishlist,
  removeProductFromWishlistProducts,
  setIsUpdating,
  setWishlistIds,
} from './actions';

// constants
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

interface UpdateProps {
  itemId: string;
}

import {
  addWishlistIdInParentStore,
  removeWishlistIdInParentStore,
} from 'shared/helpers/messaging';

export const fetchWishListIds = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.FETCHING_WISHLIST_IDS });
    return getWishlistItemIds()
      .then((res) => {
        const wishlistIds = get(res, 'data.itemIds', []);

        dispatch(setWishlistIds(wishlistIds));
        return wishlistIds;
      })
      .catch((err) => {
        log({
          errTitle: 'Error in fetching wishlist IDs',
          error: err,
          tags: { [TAG_IDENTIFIER.MODULE]: 'WISHLIST_ID_FETCHING_FAILURE' },
        });
        return [];
      });
  };
};

export const removeFromWishlist = ({ itemId }: UpdateProps) => {
  return (dispatch: Dispatch) => {
    dispatch(setIsUpdating(true));
    dispatch({ type: ActionTypes.REMOVE_PRODUCT_ID_FROM_WISHLIST_INIT });
    return removeProduct({ itemId }).then(() => {
      dispatch(setIsUpdating(false));
      dispatch(removeProductFromWishlistProducts(itemId));
      removeWishlistIdInParentStore(itemId);
    });
  };
};

export const addToWishlist = ({ itemId }: UpdateProps) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.ADD_PRODUCT_ID_TO_WISHLIST_INIT });
    dispatch(setIsUpdating(true));
    return addProduct({ itemId }).then(() => {
      dispatch(setIsUpdating(false));
      dispatch(addProductIdToWishlist(itemId));
      addWishlistIdInParentStore(itemId);
    });
  };
};
