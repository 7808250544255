// constants
import { MM_CATEGORIES, VIEW_TYPES, ICategoryData, ICategory } from './types';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

// helpers
import logger from 'shared/logger';

// constants
const DWEB_TRANSFORMER = 'DWEB_TRANSFORMER';

export const transformData = (data: { response: any }): ICategoryData[] => {
  // find master/homepage content
  const findSupercategoryData = (id: string | number) => {
    const relevantData = data.response.children_data.filter((item: { id: string | number }) => {
      return item.id === id;
    });
    return relevantData[0].children_data || [];
  };

  let masterContent = data.response.children_data.filter((masterData: ICategory) => {
    return masterData.id === MM_CATEGORIES.MASTER_DATA_ID;
  });

  let visualContent = masterContent[0].children_data
    .filter((items: ICategory) => {
      return items.name.toLowerCase() === MM_CATEGORIES.MASTER_CONTENT_ID;
    })[0]
    .children_data.sort((a, b) => {
      return a.position - b.position;
    });

  let transformedData: ICategoryData[] = [];

  visualContent.map((child) => {
    if (child.name.toLowerCase() === MM_CATEGORIES.SUPER_CATEGORY) {
      child.children_data.map((category: ICategory) => {
        if (category.name)
          transformedData.push({
            ...category,
            level: 0,
            children_data: findSupercategoryData(category.id),
            subTitle: category['sub-title'],
          });
      });
    } else if (child.name.toLowerCase() === MM_CATEGORIES.ALL_BRANDS) {
      if (child.view_type.toLowerCase() === VIEW_TYPES.LIST) {
        let transformedBrandData = {
          ...child.children_data[0],
          name: 'All Brands',
          action_url: '/all-brands',
        };
        transformedData.push(transformedBrandData);
      }
    } else if (
      child.view_type.toLowerCase() === VIEW_TYPES.LIST ||
      child.view_type.toLowerCase() === VIEW_TYPES.CARD
    ) {
      transformedData.push({ ...child, view_type: VIEW_TYPES.MORE, name: 'More' });
    } else {
      // log menu type is not supported
      // @ts-ignore
      logger.info({
        message: 'Menu type is not supported',
        tags: { [TAG_IDENTIFIER.MODULE]: DWEB_TRANSFORMER },
      });
    }
  });

  return transformedData;
};
