// our route config
import PDP from './Detail';
import PLP from './Listing';
import HOME from './Homepage';
import Search from './Search';
import MobileAuthFlow from './MobileAuthFlow';
import OfferPage from './Offerpage';
import LandingPage from './LandingPage';
import FitcodeResults from './FitcodeResults';
import FitcodeSurvey from './FitcodeSurvey';
import EmailerMessage from './EmailerMessage';
import AllReviews from './AllReviews';
import ReviewImageGallery from './ReviewImageGallery';
import ProductRating from './ProductRating';
import NotFound from './NotFound';
import MegaMenu from './MegaMenu';
import MyAccount from './MyAccount';
import ZendeskChat from './ZendeskChat';
import AppPromo from './AppPromo';
import Wishlist from './Wishlist';
import AllBrands from './AllBrands';
import CMS from './CMS';
import MyProfile from './MyProfile';
import GiftCard from './GiftCard';
import Checkout from './Cart';
import WidgetListing from './WidgetListing';
import LandingPageSDK from './DealsPlatformPages';
import Explore from './Explore';
import AddressBook from './AddressBook';
import OffersListing from 'routes/OffersListing';
import HybridPDP from './HybridPDP';
import HybridPDPVariant2 from './HybridPDPVariant2';
import DynamicPage from './DynamicPage/index';
import SizeGuide from './SizeGuideRoute';
import SizeGuideSkuVersion from './SizeGuideSkuVersion';
import AccountDelete from './AccountDelete';
import Coupon from './Coupon';

export const routesWithAppLayout = [
  {
    path: '/',
    component: HOME,
    exact: true,
  },
  {
    path: '/lp-fit-advisor',
    component: FitcodeResults,
  },
  {
    path: '/international',
    component: HOME,
  },
  {
    path: '/detail/:sku',
    component: PDP,
  },

  {
    path: '/:slug/p/:id',
    component: PDP,
  },
  {
    path: '/catalog/product/view/id/:id/*',
    component: PDP,
  },
  {
    path: ['/catalogsearch/result'],
    component: Search,
  },
  {
    path: '/dp/:slug',
    component: DynamicPage,
  },
  {
    path: '/*/c/:id',
    component: PLP,
  },
  {
    path: '/op/:id',
    component: OffersListing,
  },
  {
    path: '/offer.html',
    component: OfferPage,
  },
  {
    path: '/lp/:slug',
    component: LandingPage,
  },
  {
    path: '/lp-sdk/:slug',
    component: LandingPageSDK,
  },
  {
    path: '/feedback/(success|failure)',
    component: EmailerMessage,
  },
  {
    path: '/chat-with-us',
    component: ZendeskChat,
  },
  {
    path: '/app-promo',
    component: AppPromo,
  },
  {
    path: '/wishlist',
    component: Wishlist,
  },
  {
    path: '/all-brands',
    component: AllBrands,
  },
  {
    path: '/explore',
    component: Explore,
  },
  {
    path: '/cp/:slug',
    component: CMS,
  },
  {
    path: '/giftcard/*',
    component: GiftCard,
  },
  {
    path: '/widget-listing/:id',
    component: WidgetListing,
  },
  {
    path: '/my-account',
    component: MyAccount,
  },
  {
    path: '/hp/:id',
    component: HybridPDP,
  },
  {
    path: '/hc/:id',
    component: HybridPDPVariant2,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const routesWithoutAppLayout = [
  {
    path: '/:slug/p/:id/review-images',
    component: ReviewImageGallery,
    exact: true,
  },
  {
    path: '/:slug/p/:id/all-reviews',
    component: AllReviews,
    exact: true,
  },
  {
    path: '/authorization',
    component: MobileAuthFlow,
  },
  {
    path: '/fit-advisor-survey',
    component: FitcodeSurvey,
  },
  {
    path: '/product-rating/:orderId/:productId',
    component: ProductRating,
  },
  {
    path: '/mega-menu-shop',
    component: MegaMenu,
  },

  {
    path: '/my-profile',
    component: MyProfile,
  },
  {
    path: '/v2/checkout',
    component: Checkout,
  },
  {
    path: '/address-book',
    component: AddressBook,
  },
  {
    path: '/size-guide/product/:id',
    component: SizeGuide,
  },
  {
    path: '/size-guide/sku',
    component: SizeGuideSkuVersion,
  },
  {
    path: '/delete-account',
    component: AccountDelete,
  },
  {
    path: '/coupon/offers',
    component: Coupon,
  },
];
