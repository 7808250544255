//constants
import { PROXY_ENDPOINTS } from 'shared/constants/proxy';

//helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';

const { API_REDIRECTION_PATH } = getDomainConfig();

export enum ROUTES {
  PDP,
  PDP_WITH_ID,
  PDP_WITH_ID_V3,
  GET_CUSTOMER,
  WIDGETS,
  ADD_TO_CART,
  ADD_TO_CART_PLATFORM,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  SOCIAL_AUTH,
  HOME,
  AD_PLATFORM_API,
  SEARCH_AUTO_SUGGEST,
  OFFERS,
  MINI_CART,
  SET_GDPR,
  LISTING,
  LISTING_OFFER,
  TRENDING_SEARCHES,
  TRENDING_SEARCHES_V3,
  PDP_OFFERS,
  PDP_OFFERS_V2,
  GENERATE_OTP,
  RESEND_OTP,
  LINK_MOBILE_TO_ACCOUNT,
  LINK_EMAIL_TO_ACCOUNT,
  MERGE_ACCOUNT,
  ASSOCIATED_EMAILS_WITH_ACCOUNT,
  LOGIN_USING_OTP,
  CREATE_EMAIL_FOR_REGISTERED_MOBILE,
  PIN_CODE_SERVICE,
  PDP_EXPLORE,
  CHECK_USER_EXISTS,
  OMS_ORDER_LIST,
  FIT_ADVISOR_RESULTS,
  ALL_REVIEWS,
  PRODUCT_SPECIFICATIONS,
  SUBMIT_REVIEW,
  ORDER_DETAILS,
  PRE_SIGNED_URL,
  FETCH_REVIEW,
  ALL_IMAGES_FOR_REVIEWS,
  LIKED_PRODUCT_REVIEW,
  MEGA_MENU_V2,
  MEGA_MENU_V4,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  GET_WISHLIST_ITEMS,
  GET_WISHLIST_ITEM_IDS,
  CMS,
  GET_MY_PROFILE,
  SAVE_MY_PROFILE,
  UPDATE_PROFILE_PIC,
  GET_PROFILE_IMAGE,
  EMI,
  UPDATE_CART_ITEM,
  REPLACE_CART_ITEM_SIZE,
  REMOVE_CART_ITEM,
  GET_CART_REFRESH,
  GET_CART_DETAILS,
  APPLY_COUPON,
  REMOVE_COUPON,
  GET_ADDRESSES,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  SET_SHIPPING_ADDRESS,
  POSTCODE_SERVICABLE,
  GET_CART_WIDGETS,
  GET_COUPONS,
  GET_DELIVERY_AND_SHIPPING_INFO,
  GET_DELIVERY_AND_SHIPPING_INFO_V2,
  GET_COUPONS_V2,
  REMOVE_COUPON_V2,
  APPLY_COUPON_V2,
  GET_COUPON_V2,
  TOGGLE_CART_REWARD,
  TRUECALLER_INITIATE,
  TRUECALLER_STATUS,
  BULK_BEST_OFFER,
  DYNAMIC_PAGE_CONFIG,
  GET_FREE_SAMPLE_LIST_ITEMS,
  ADD_FREE_SAMPLE,
  REMOVE_FREE_SAMPLE,
  GET_PROFILE_DETAILS_FOR_IMPORT,
  IMPORT_EMAIL,
  GET_ESTIMATED_DELIVERY_INFO,
  ADD_ADDRESS_V2,
  LOGOUT,
  CHECK_ACCOUNT_DELETE_ELIGIBILITY,
  ACCOUNT_DELETE,
  ACCOUNT_DELETE_OTP,
  RESEND_ACCOUNT_DELETE_OTP,
  PINCODE_SERVICE_EDD_FETCH,
  FETCH_UPS_DATA,
  RESTORE_CART_ITEM,
  PRODUCT_REVIEW_FORM,
  ALL_REVIEWS_LISTING_SUBMIT_V2,
  OMS_ORDER_LIST_V2,
  GET_COUPONPAGE,
}

export const urls: { [key: string]: string } = {
  [ROUTES.PDP]: '/rest/appapi/V3/products/',
  [ROUTES.PDP_WITH_ID]: '/rest/appapi/V2/products/id/',
  [ROUTES.PDP_WITH_ID_V3]: '/rest/appapi/V3/products/id/',
  [ROUTES.WIDGETS]: '/rest/appapi/V2/getProductWidgets',
  [ROUTES.GET_CUSTOMER]: '/rest/appapi/V1/customer',
  [ROUTES.ADD_TO_CART]: '/rest/appapi/V1/carts/mine/items',
  [ROUTES.HOME]: '/?ptype=homepage',
  [ROUTES.AD_PLATFORM_API]: '/inventory/data/json/',
  [ROUTES.SEARCH_AUTO_SUGGEST]: '/rest/appapi/V2/search/suggestion',
  [ROUTES.OFFERS]: '/rest/appapi/V1/offers',
  [ROUTES.MINI_CART]: '/customcart/minicart/index',
  [ROUTES.SET_GDPR]: '/rest/appapi/V1/setCustomergdprOpt',
  [ROUTES.LISTING]: '/rest/appapi/V2/categories/products',
  [ROUTES.LISTING_OFFER]: '/rest/appapi/V2/offer/products',
  [ROUTES.TRENDING_SEARCHES]: '/rest/appapi/V2/search/custom',
  [ROUTES.TRENDING_SEARCHES_V3]: '/rest/appapi/V3/search/custom',
  [ROUTES.PDP_OFFERS]: '/offer/api/v1/product/<PRODUCT_ID>/offer',
  [ROUTES.PDP_OFFERS_V2]: '/offer/api/v1/product/customer/offer',
  [ROUTES.GENERATE_OTP]: '/webscripts/api/otp/generate',
  [ROUTES.RESEND_OTP]: '/webscripts/api/otp/resend',
  [ROUTES.LINK_MOBILE_TO_ACCOUNT]: '/webscripts/api/customer/linkMobileToAccount',
  [ROUTES.LINK_EMAIL_TO_ACCOUNT]: '/webscripts/api/pub/customer/linkAccount',
  [ROUTES.MERGE_ACCOUNT]: '/webscripts/api/pub/customer/mergeAccount',
  [ROUTES.ASSOCIATED_EMAILS_WITH_ACCOUNT]: '/webscripts/api/pub/customer/mine',
  [ROUTES.LOGIN_USING_OTP]: '/webscripts/api/pub/customer/login',
  [ROUTES.CREATE_EMAIL_FOR_REGISTERED_MOBILE]: '/webscripts/api/pub/customer/register',
  [ROUTES.PIN_CODE_SERVICE]: '/rest/appapi/V1/service/pincode',
  [ROUTES.PDP_EXPLORE]: '/product/<PRODUCT_ID>/posts/',
  [ROUTES.CHECK_USER_EXISTS]: '/rest/appapi/V1/integration/checkCustomerExists',
  [ROUTES.OMS_ORDER_LIST]: '/omsApis/v1/orderList',
  [ROUTES.OMS_ORDER_LIST_V2]: '/omsApis/v2/orders',
  [ROUTES.FIT_ADVISOR_RESULTS]: '/dp/inventory/data/json/?fitcodeuuid=',
  [ROUTES.ALL_REVIEWS]: '/products/<PRODUCT_ID>/reviews',
  [ROUTES.ALL_IMAGES_FOR_REVIEWS]: '/products/<PRODUCT_ID>/reviews/images',
  [ROUTES.PRODUCT_SPECIFICATIONS]: '/rest/appapi/V2/products/specific',
  [ROUTES.SUBMIT_REVIEW]: '/products/<designCode>/reviews',
  [ROUTES.ORDER_DETAILS]: '/omsApis/v1/getOrderById',
  [ROUTES.PRE_SIGNED_URL]: '/products/reviews/images/url',
  [ROUTES.FETCH_REVIEW]: '/products/reviews',
  [ROUTES.ADD_TO_CART_PLATFORM]: '/cartapi/v1/item/add',
  [ROUTES.LIKED_PRODUCT_REVIEW]: '/products/<PRODUCT_ID>/reviews/<REVIEW_ID>/like',
  [ROUTES.MEGA_MENU_V2]: '/rest/appapi/V3/categories',
  [ROUTES.MEGA_MENU_V4]: '/rest/appapi/V4/categories',
  [ROUTES.ADD_TO_WISHLIST]: '/wishlist/api/v1/wishlist/addItem',
  [ROUTES.REMOVE_FROM_WISHLIST]: '/wishlist/api/v2/wishlist/removeItem',
  [ROUTES.GET_WISHLIST_ITEMS]: '/wishlist/api/v1/wishlist/items',
  [ROUTES.GET_WISHLIST_ITEM_IDS]: '/wishlist/api/v1/wishlist/itemIds',
  [ROUTES.CMS]: '/endpoint/info',
  [ROUTES.GET_MY_PROFILE]: '/webscripts/api/pub/customer/getMyProfileData',
  [ROUTES.SAVE_MY_PROFILE]: '/webscripts/api/pub/customer/saveProfileData',
  [ROUTES.GET_PROFILE_DETAILS_FOR_IMPORT]: '/fashion/customer/getProfileDetailsForImport',
  [ROUTES.UPDATE_PROFILE_PIC]: '/webscripts/api/pub/customer/uploadProfileImage',
  [ROUTES.GET_PROFILE_IMAGE]: '/webscripts/api/pub/customer/getProfileImage',
  [ROUTES.IMPORT_EMAIL]: '/fashion/customer/importEmail',
  [ROUTES.EMI]: '/payment/emi/getEmiOptions/v2?amount=<PRICE>&domain=<DOMAIN>',
  [ROUTES.UPDATE_CART_ITEM]: '/cartapi/v1/item/update',
  [ROUTES.REPLACE_CART_ITEM_SIZE]: '/cartapi/v1/item/replace',
  [ROUTES.REMOVE_CART_ITEM]: '/cartapi/v1/item/remove',
  [ROUTES.GET_CART_REFRESH]: '/cartapi/v1/cart/refresh',
  [ROUTES.GET_CART_DETAILS]: '/cartapi/v1/cart/details',
  [ROUTES.APPLY_COUPON]: '/cartapi/v1/coupon/apply',
  [ROUTES.REMOVE_COUPON]: '/cartapi/v1/coupon/remove',
  [ROUTES.GET_ADDRESSES]: '/rest/appapi/V1/customer/addresses',
  [ROUTES.ADD_ADDRESS]: '/rest/appapi/V1/customer/addresses/add',
  [ROUTES.UPDATE_ADDRESS]: '/rest/appapi/V1/customer/addresses/edit',
  [ROUTES.SET_SHIPPING_ADDRESS]: '/rest/appapi/V1/setShippingAddress',
  [ROUTES.POSTCODE_SERVICABLE]: '/rest/appapi/V1/customer/addresses/postcode',
  [ROUTES.GET_CART_WIDGETS]: '/rest/appapi/V2/getCartWidgets',
  [ROUTES.GET_COUPONS]: '/rest/appapi/V1/carts/coupons',
  [ROUTES.GET_DELIVERY_AND_SHIPPING_INFO]: '/serviceability/product/serviceable',
  [ROUTES.GET_DELIVERY_AND_SHIPPING_INFO_V2]: '/serviceability/product/serviceable',
  [ROUTES.GET_COUPONS_V2]: '/cartapi/v1/coupon/list',
  [ROUTES.APPLY_COUPON_V2]: '/cartapi/v1/coupon/apply',
  [ROUTES.REMOVE_COUPON_V2]: '/cartapi/v1/coupon/remove',
  [ROUTES.GET_COUPON_V2]: '/coupon/api/v2/coupon/description',
  [ROUTES.TOGGLE_CART_REWARD]: '/cartapi/v1/reward/',
  [ROUTES.TRUECALLER_INITIATE]: '/truecaller/v1/initiate',
  [ROUTES.TRUECALLER_STATUS]: '/truecaller/v1/status',
  [ROUTES.BULK_BEST_OFFER]: '/offer/api/v1/product/customer/best/offers',
  [ROUTES.DYNAMIC_PAGE_CONFIG]: '/api/v1/seo/getmeta',
  [ROUTES.GET_FREE_SAMPLE_LIST_ITEMS]: '/cartapi/v1/sample/list',
  [ROUTES.ADD_FREE_SAMPLE]: '/cartapi/v1/sample/add',
  [ROUTES.REMOVE_FREE_SAMPLE]: '/cartapi/v1/sample/remove',
  [ROUTES.GET_ESTIMATED_DELIVERY_INFO]: '/edd/product/edd/default/fetch',
  [ROUTES.ADD_ADDRESS_V2]: '/cartapi/v2/address/add',
  [ROUTES.LOGOUT]: '/fashion/customer/web/logout',
  [ROUTES.CHECK_ACCOUNT_DELETE_ELIGIBILITY]: '/user/checkAccountDeleteEligibility',
  [ROUTES.ACCOUNT_DELETE]: '/user/accountDelete',
  [ROUTES.ACCOUNT_DELETE_OTP]: '/user/otp/sendAccountDeleteOtp',
  [ROUTES.RESEND_ACCOUNT_DELETE_OTP]: '/user/otp/resendAccountDeleteOtp',
  [ROUTES.PINCODE_SERVICE_EDD_FETCH]: '/edd/pincode-product/edd/default/fetch',
  [ROUTES.FETCH_UPS_DATA]: '/nf/user',
  [ROUTES.RESTORE_CART_ITEM]: '/cartapi/v1/cart/restore',
  [ROUTES.PRODUCT_REVIEW_FORM]: '/v2/products/review-form',
  [ROUTES.ALL_REVIEWS_LISTING_SUBMIT_V2]: '/v2/products/<PRODUCT_ID>/reviews',
  [ROUTES.GET_COUPONPAGE]: '/offer/api/v2/customer/offer',
};

const whiteListEndpoints = new Map([
  [ROUTES.GET_DELIVERY_AND_SHIPPING_INFO, true],
  [ROUTES.GET_COUPONS_V2, true],
  [ROUTES.APPLY_COUPON_V2, true],
  [ROUTES.REMOVE_COUPON_V2, true],
  [ROUTES.GET_COUPON_V2, true],
  [ROUTES.TOGGLE_CART_REWARD, true],
  [ROUTES.TRUECALLER_INITIATE, true],
  [ROUTES.TRUECALLER_STATUS, true],
  [ROUTES.BULK_BEST_OFFER, true],
  [ROUTES.DYNAMIC_PAGE_CONFIG, true],
  [ROUTES.GET_FREE_SAMPLE_LIST_ITEMS, true],
  [ROUTES.ADD_FREE_SAMPLE, true],
  [ROUTES.REMOVE_FREE_SAMPLE, true],
  [ROUTES.GET_ESTIMATED_DELIVERY_INFO, true],
  [ROUTES.ADD_ADDRESS_V2, true],
  [ROUTES.CHECK_ACCOUNT_DELETE_ELIGIBILITY, true],
  [ROUTES.ACCOUNT_DELETE_OTP, true],
  [ROUTES.ACCOUNT_DELETE, true],
  [ROUTES.RESEND_ACCOUNT_DELETE_OTP, true],
  [ROUTES.EMI, true],
  [ROUTES.UPDATE_CART_ITEM, true],
  [ROUTES.REPLACE_CART_ITEM_SIZE, true],
  [ROUTES.REMOVE_CART_ITEM, true],
  [ROUTES.GET_CART_REFRESH, true],
  [ROUTES.GET_CART_DETAILS, true],
  [ROUTES.APPLY_COUPON, true],
  [ROUTES.REMOVE_COUPON, true],
  [ROUTES.OMS_ORDER_LIST, true],
  [ROUTES.ALL_REVIEWS, true],
  [ROUTES.ALL_IMAGES_FOR_REVIEWS, true],
  [ROUTES.SUBMIT_REVIEW, true],
  [ROUTES.ORDER_DETAILS, true],
  [ROUTES.PRE_SIGNED_URL, true],
  [ROUTES.FETCH_REVIEW, true],
  [ROUTES.ADD_TO_CART_PLATFORM, true],
  [ROUTES.LIKED_PRODUCT_REVIEW, true],
  [ROUTES.ADD_TO_WISHLIST, true],
  [ROUTES.REMOVE_FROM_WISHLIST, true],
  [ROUTES.GET_WISHLIST_ITEMS, true],
  [ROUTES.GET_WISHLIST_ITEM_IDS, true],
  [ROUTES.PDP_OFFERS, true],
  [ROUTES.PDP_OFFERS_V2, true],
  [ROUTES.PINCODE_SERVICE_EDD_FETCH, true],
  [ROUTES.FETCH_UPS_DATA, true],
  [ROUTES.RESTORE_CART_ITEM, true],
  [ROUTES.PRODUCT_REVIEW_FORM, true],
  [ROUTES.OMS_ORDER_LIST_V2, true],
  [ROUTES.PRODUCT_REVIEW_FORM, true],
  [ROUTES.PRODUCT_REVIEW_FORM, true],
  [ROUTES.ALL_REVIEWS_LISTING_SUBMIT_V2, true],
  [ROUTES.OMS_ORDER_LIST_V2, true],
  [ROUTES.GET_COUPONPAGE, true],
]);

export const getAPIHost = () => {
  return __SERVER__ ? process.env.API_INTERNAL_HOST : process.env.API_HOST;
};

export const getAdplatformAPIHost = () => {
  return __SERVER__ ? process.env.AD_PLATFORM_INTERNAL_HOST : process.env.AD_PLATFORM_HOST;
};

export const getUrl = (uri: ROUTES): string => {
  if (whiteListEndpoints.get(uri) === true) {
    return `${getAPIHost()}/${API_REDIRECTION_PATH}${urls[uri]}`;
  }
  return `${getAPIHost()}${urls[uri]}`;
};

export const getAdPlatformURL = (uri: ROUTES): string => {
  return `${getAdplatformAPIHost()}${urls[uri]}`;
};

export const getExploreURL = (uri: ROUTES) => {
  if (process.env.NODE_ENV === 'development') {
    return `${getAPIHost()}/${PROXY_ENDPOINTS.EXPLORE}${urls[uri]}`;
  }

  return `${process.env.EXPLORE_PLATFORM_HOST}${urls[uri]}`;
};

export const getFitAdvisorURL = (uri: ROUTES) => {
  if (process.env.NODE_ENV === 'development') {
    return `${getAPIHost()}/${PROXY_ENDPOINTS.FITADVISOR}${urls[uri]}`;
  }

  return `${process.env.FITADVISOR_API_HOST}${urls[uri]}`;
};

export const getCMSURL = (uri: ROUTES) => {
  return `${process.env.CMS_INTERNAL_HOST}${urls[uri]}`;
};
