// libs
import isEmpty from 'lodash/isEmpty';

// helpers
import { getCookie, setCookie } from 'shared/helpers/utils';
import { log } from 'shared/helpers/newRelic';

// constants
import {
  LOGIN_ERROR_COOKIE,
  OMS_LOGIN_COOKIE_NAME,
  VALID_LOGIN_ERROR,
  QUERY_STRING_AUTH_INITIATER,
  QUERY_STRING_AUTH_VALUE,
  OTP_CONFIG,
} from './constants';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

export const hasSocialLoginError = () => {
  const loginErrorCookie = getCookie(LOGIN_ERROR_COOKIE);

  return (
    isEmpty(loginErrorCookie) === false &&
    String(loginErrorCookie).toLowerCase() === VALID_LOGIN_ERROR
  );
};

export const clearLoginError = () => {
  setCookie(LOGIN_ERROR_COOKIE, '', 0);
};

export const isShowAuthRequested = (search = '') => {
  const urlParams = new URLSearchParams(search);

  return (
    urlParams.has(QUERY_STRING_AUTH_INITIATER) &&
    // @ts-ignore
    urlParams.get(QUERY_STRING_AUTH_INITIATER).toLowerCase() === QUERY_STRING_AUTH_VALUE
  );
};

export const getAuthRedirectUrl = () => {
  if (isEmpty(document.referrer)) {
    return undefined;
  }

  try {
    const referrerUrl = new URL(document.referrer);

    // return referrer only if matches our host
    if (window.location.host !== referrerUrl.host) {
      return undefined;
    }

    return document.referrer;
  } catch (e) {
    return undefined;
  }
};

export const getOTPFlowStatus = () => {
  let isOTPFlowEnabledForMobile = false;
  let isOTPFlowEnabledForDesktop = false;

  try {
    const { isEnabledForMobile, isEnabledForDesktop } = OTP_CONFIG;
    isOTPFlowEnabledForMobile = isEnabledForMobile;
    isOTPFlowEnabledForDesktop = isEnabledForDesktop;
  } catch (err) {
    // do Nothing
  }
  return { isOTPFlowEnabledForMobile, isOTPFlowEnabledForDesktop };
};

export const removeOMSPlatformSessionIdentifiers = () => {
  try {
    setCookie(OMS_LOGIN_COOKIE_NAME, '', -1);
    window.sessionStorage.removeItem(OMS_LOGIN_COOKIE_NAME);
  } catch (error) {
    log({
      errTitle: 'Error in remove OMS Platform',
      error,
      tags: { [TAG_IDENTIFIER.MODULE]: 'MY_ACCOUNT_LOGOUT' },
    });
  }
};
