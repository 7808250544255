//libs
import { memo, useCallback, useEffect } from 'react';

//helpers
import { getRequestId, getTruecallerDetails } from 'shared/api/truecaller';
import { getTruecallerSDKUrl, getApiStatus, API_STATUS, TruecallerApiResponse } from './helpers';
import { getUserDetailsToStore } from 'shared/components/OTPVerification/helpers';
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

//defs
import { AppState } from 'shared/store/app/types';

interface TruecallerLoginProps {
  showFallback: () => void;
  updateCustomerDetails: (user: AppState['user']['customerData']) => void;
  hide: () => void;
  redirectUrl?: string;
  onBottomSheetVisible?: VoidFunction;
  isUserLoggedIn: boolean;
}

function TruecallerLogin({
  showFallback,
  updateCustomerDetails,
  hide,
  redirectUrl = '',
  onBottomSheetVisible,
  isUserLoggedIn,
}: TruecallerLoginProps) {
  const { pollingDelay, pollingTimeout } = useRemoteConfig('truecallerLogin');

  const handleTruecaller = useCallback(
    (apiStatus: API_STATUS, timer: number, userResponse: TruecallerApiResponse) => {
      switch (apiStatus) {
        case API_STATUS.NOT_AUTHENTIC_USER: {
          // When API gives status as 'fail', clear the interval of calling status API multiple times

          clearInterval(timer);
          hide();
          return;
        }

        case API_STATUS.ALTERNATE_NUMBER_SELECTED: {
          // If user selects 'Use another mobile number' option, show fallback of existing login bottomsheet.
          // Also, clear the interval of calling status API multiple times.

          showFallback();
          clearInterval(timer);
          return;
        }

        case API_STATUS.TRUECALLER_LOGIN_SELECTED: {
          // If user chooses to login through truecaller, save user details fetched from status API into redux and show toast message.

          const userData = getUserDetailsToStore(userResponse);
          updateCustomerDetails({
            ...userData,
            showLoginToast: true,
          });
          clearInterval(timer);
          hide();

          if (redirectUrl !== '') window.location.href = redirectUrl;
          return;
        }

        case API_STATUS.BACK_BUTTON_CLICKED: {
          // user clicked back button on seeing truecaller bottomsheet.

          clearInterval(timer);
          hide();
          return;
        }

        default: {
          // log edge cases which are not known
          clearInterval(timer);
          hide();
          break;
        }
      }
    },
    [hide, redirectUrl, showFallback, updateCustomerDetails]
  );

  const checkTruecallerStatus = useCallback(
    (rID: string) => {
      try {
        window.location = getTruecallerSDKUrl(rID);

        setTimeout(() => {
          if (!document.hasFocus()) {
            const timer = window.setInterval(() => {
              getTruecallerDetails({ requestId: rID })
                .then((data) => {
                  const { status, response } = data;
                  const apiStatus = getApiStatus(status, response);
                  if (apiStatus !== API_STATUS.API_PROCESSING) {
                    handleTruecaller(apiStatus, timer, response.user_response);
                  }
                })
                .catch(() => {
                  // If status api fails, clear the interval of calling status API multiple times.
                  clearInterval(timer);
                  // setState to false of showing login bottomsheet
                  hide();
                });
            }, pollingDelay);

            setTimeout(() => {
              // Clear the timer of calling status API multiple times once 'pollingTimeout' seconds are achieved
              clearInterval(timer);
            }, pollingTimeout);
          } else {
            // If truecaller SDK doesnt work and truecaller sheet doesnt open, show fallback of existing login bottomsheet.
            showFallback();
          }
        }, 600);
      } catch (err) {
        showFallback();
        // If anything fails in try block, show fallback of existing login bottomsheet and log error.
        log({
          errTitle: 'Error in truecaller login',
          error: err,
          tags: { [TAG_IDENTIFIER.MODULE]: 'TRUECALLER_LOGIN' },
        });
      }
    },
    [handleTruecaller, hide, pollingDelay, pollingTimeout, showFallback]
  );

  useEffect(() => {
    onBottomSheetVisible && onBottomSheetVisible();
    getRequestId()
      .then((id: string) => {
        checkTruecallerStatus(id);
      })
      .catch(() => {
        showFallback();
      });
  }, [checkTruecallerStatus, onBottomSheetVisible, showFallback]);

  if (isUserLoggedIn) return null;
  return null;
}

export default memo(TruecallerLogin);
