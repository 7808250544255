//libs
import React, { memo } from 'react';

//global components
import RouteChunkRenderer from 'shared/components/RouteChunkRenderer';
import ChunkFallback from 'shared/components/ChunkFallback';

//components
import CouponPage from 'shared/layouts/CouponPage/Mobile';
import RedirectToHomePage from 'shared/components/RedirectToHomePage';

function Coupon() {
  return (
    <RouteChunkRenderer
      mobileComponent={<CouponPage fallback={<ChunkFallback />} />}
      desktopComponent={<RedirectToHomePage />}
    />
  );
}

export default memo(Coupon);
