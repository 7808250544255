// helpers
import { log } from 'shared/helpers/newRelic';

// constants
import { ERROR_TAGS } from 'shared/helpers/newRelic/constants';

// libs
import { TYPE } from './constants';

import asyncDeferScript, { LOAD_TYPE } from 'shared/helpers/dom/deferScript';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const initialize = () => {
  const gtmId = process.env.REACT_APP_GTM_ID;

  if (gtmId) {
    asyncDeferScript(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, LOAD_TYPE.ASYNC);
  }
};

// push  data to GTM dataLayer
export const pushToGTMDatalayer = (data: Record<string, any>) => {
  try {
    window.dataLayer.push(data);
  } catch (e) {
    log({
      errTitle: 'Error in pushToGTMDatalayer',
      err: e,
      tags: { module: ERROR_TAGS.ANALYTICS },
    });
  }
};

// push  multiple events to GTM dataLayer
export const pushMultipleEventsToGTMDatalayer = (events: Record<string, any>[]) => {
  try {
    window.dataLayer.push(...events);
  } catch (e) {
    log({
      errTitle: 'Error in pushMultipleEvents To GTMDatalayer',
      err: e,
      tags: { module: ERROR_TAGS.ANALYTICS },
    });
  }
};

// push event data with or without event
export const sendEventToGTM = (event: string, data?: Record<string, any>) => {
  try {
    // check if event is not empty
    if (event == undefined) {
      throw new Error(`Event is undefined`);
    }

    // create combined event and data object
    const dataObject = { event, ...(data !== undefined ? { data } : {}), type: TYPE.EVENT };

    // push data to datalayer
    pushToGTMDatalayer(dataObject);
  } catch (e) {
    log({
      errTitle: 'Error in sendEventToGTM',
      err: e,
      tags: { module: ERROR_TAGS.ANALYTICS },
    });
  }
};

// push event data without event
export const sendDataToGTM = (data: Record<string, any>) => {
  try {
    pushToGTMDatalayer({ data, type: TYPE.DATA_PUSH });
  } catch (e) {
    log({
      errTitle: 'Error in sendDataToGTM',
      err: e,
      tags: { module: ERROR_TAGS.ANALYTICS },
    });
  }
};
